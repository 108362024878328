import * as React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { ReviewWithVideoSection } from 'components/ReviewWithVideoSection';

export const IeltsStudentExperience = () => {
  const ieltsLandingData = useStaticQuery(graphql`
    {
      gcms {
        jsonContanier(where: { name: "IELTS_Landing" }) {
          content
        }
      }
    }
  `);
  return (
    <ReviewWithVideoSection
      data={ieltsLandingData.gcms.jsonContanier.content.reviews}
      footerTitle="HỌC VIÊN"
      videoPosition="left"
    />
  );
};
