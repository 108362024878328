import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { Courses } from 'shared/CourseLandingV3/Courses';
import { IeltsHeader } from 'shared/IeltsLanding/Ielts/IeltsHeader';
import { IeltsLearningPath } from 'shared/IeltsLanding/Ielts/IeltsLearningPath';
import { IeltsStudentExperience } from 'shared/IeltsLanding/Ielts/IeltsStudentExperience';
import { CourseFaqsByClassGroup } from 'shared/CourseLandingV3/CourseFaqsByClassGroup';
import HallOfFame from 'shared/CourseLandingV3/HallOfFame';

const Ielts = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="Khóa học IELTS"
        slug={location.pathname}
        seoTitle="Khóa học IELTS"
        seoDesc="Các khóa học IELTS tại DOL English luôn cam kết đảm bảo đầu ra cho học viên với phương pháp Linearthinking và nền tảng công nghệ độc quyền do DOL phát triển."
        description="Các khóa học IELTS tại DOL English luôn cam kết đảm bảo đầu ra cho học viên với phương pháp Linearthinking và nền tảng công nghệ độc quyền do DOL phát triển."
        featureImg="cm92jrbei91tt070zq2asqpew"
      />
      <Space>
        <IeltsHeader />
      </Space>
      <SpaceOnlyTop>
        <Courses
          level="Khóa IELTS cấp tốc"
          headerTitle="Các khóa học IELTS cấp tốc"
        />
      </SpaceOnlyTop>
      <SpaceOnlyTop>
        <Courses
          level="Khóa IELTS cơ bản"
          headerTitle="Các khóa học IELTS cơ bản"
        />
      </SpaceOnlyTop>
      <Space>
        <Courses
          level="Khóa IELTS nâng cao"
          headerTitle="Các khóa học IELTS Nâng cao"
        />
        <IeltsLearningPath />
        <HallOfFame
          program="IELTS"
          title="2.000+ học viên"
          description="đạt IELTS cao từ 7.0"
        />
        <IeltsStudentExperience />
        <CourseFaqsByClassGroup classGroup="IELTS" />
      </Space>
    </MainLayout>
  );
};

const SpaceOnlyTop = styled.div`
  overflow: hidden;
  > section {
    padding-top: 64px !important;
    ${fromScreen(776)} {
      padding-top: 100px !important;
    }
  }
  .section-courses {
    padding: 0 0 0 16px !important;
  }
  ${fromScreen(776)} {
    .section-courses {
      padding: 0px !important;
    }
  }
`;

const Space = styled.div`
  overflow: hidden;
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
  .section-courses {
    padding: 0 0 0 16px !important;
  }
  ${fromScreen(776)} {
    .section-courses {
      padding: 0px !important;
    }
  }
`;

export default React.memo(Ielts);
